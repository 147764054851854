/* eslint-disable default-case */
import React, { Component, useState } from "react";
import { useEffect } from "react";
import CheckoutForm from "../checkout-form";
import { useLocation } from "react-router-dom";
import SubscriptionHelper from "../../helper-methods/subscription-helper";
import Swal from "sweetalert2";

const SubscriptionWizard = ({ gotoSampleMessages }) => {
  const [screenType, setScreenType] = useState("screen2");
  const { search } = useLocation();

  const _checkIfRedirected = async () => {
    const query = new URLSearchParams(search);
    const paramField = query.get("pt");

    if (paramField?.length) {
      if (paramField === "s") {
        // Success
        await SubscriptionHelper.addUserToGroup();
        Swal.fire("You have successfully subscribed", "", "success");
      } else {
        // Error
      }
    }
  };

  useEffect(() => {
    _checkIfRedirected();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [screenType]);

  const _renderStep = () => {
    switch (screenType) {
      case "screen1": {
        return (
          <>
            <div className="bigHeading">Welcome to the site. <span>We're glad you found us</span>.</div>

            <div className="onlyForMobile mb-3 d-block d-md-none">  {/* this image is in two place. check home-page.js */}
              <img
                src={require("../../assets/img/sopher2.jpg")}
                // src="https://im4.ezgif.com/tmp/ezgif-4-89922e04e5.png"
                className="img-fluid"
                alt
              />
            </div>

            {/* <div className="smallHeading">The two guiding principles of this texting platform are Anonymity and Doing Good.</div> */}

            <p>In our fast-paced modern society, perhaps more than ever, we can benefit from the time-tested wisdom of ethical behavior.  Here you can subscribe to receive a daily text of philosophical wisdom and motivation from notable Stoics and other contemporary philosophers.  Each daily text imparts knowledge, insight, and encouragement to do good, to be good, to be better, and make a difference in the lives of those in your circle of influence, whether for yourself, your family, your children, or in your profession.</p>

            <p className="smallHeading">Consider a <a href="#trial" className="link">7-day No Charge Preview</a>, by clicking on <a href="#subscription" className="link scrollto">Why Subscribe</a>.</p>

            <p><span className="highlight">Subscriber Support <a href="mailto:joel@MDDtext.com" className="link">joel@MDDtext.com</a></span></p>

            <p>Many thanks to the several companies and their talented technical departments that made it possible to bring this content to subscribers.   I’m grateful for the technology that allows us to disseminate so much information, so quickly, to so many people.   The Stoics couldn’t have imagined doing what we are able to do with today’s technology.   Let’s honor them by using our resources and technology wisely to create more goodness and a better world.</p>
          </>
        );
      }
      case "screen2": {
        return (
          <>
            <CheckoutForm />
          </>
        );
      }
    }
  };

  return (
    <>
      <div className="content pt-4 pt-lg-0">{_renderStep()}</div>
    </>
  );
};

export default SubscriptionWizard;
